.blue-button-back {
    padding: 10px;
    background-color: #5DBCEE;
    border-radius: 5px;
    cursor: pointer;
}

.blue-button-back :hover {
    background-color: #5DBCEE;
}

.blue-button-text {
    color: white;
    /*padding: 15px 0px 5px 0px;*/
    user-select: none;
}

.icon-background {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height:30px;
    border: 2px solid #E8E8E8;
    border-radius: 5px;
    background-color: #FFF;
}

.connection-header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    background-color: #FAFAFA;
}
