@font-face {
  font-family:Poppins_Regular;
  font-style:normal;
  src:  local(Poppins),
  url(fonts/Poppins-Regular.ttf) format('truetype')
}

@font-face {
  font-family:Poppins_SemiBold;
  font-style: normal;
  src:  local(Poppins SemiBold),
  url(fonts/Poppins-SemiBold.ttf) format('truetype')
}

@font-face {
  font-family:SF_Pro_Text;
  font-style:normal;
  src:  local(SF Pro Text),
  url(fonts/SFProText-Regular.ttf) format('truetype')
}

@font-face {
  font-family: SF_Compact_Display;
  font-style:normal;
  src:  local(SF Compact Display),
  url(fonts/SFCompactDisplay-Regular.otf) format('opentype')
}

html * {
  font-family: Poppins_Regular;
}

.heading {
  font-family: Poppins-Regular;
}

/*Animated App Logo */
/* WebKit and Opera browsers */
.image {
  /*position: absolute;*/
  /*top: 50%;*/
  /*left: 50%;*/
  /*width: 120px;*/
  /*height: 120px;*/
  /*margin:-60px 0 0 -60px;*/
  -webkit-animation:spin 4s linear infinite;
  -moz-animation:spin 4s linear infinite;
  animation:spin 4s linear infinite;
}
@-moz-keyframes spin { 100% { -moz-transform: rotateY(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotateY(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotateY(360deg); transform:rotateY(360deg); } }
/*Animated App Logo Upto here */

.disabled-field {
  color: grey;
}
